import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import type { PageIntroStoryblok } from 'shared/types';

const SbPageIntro = ({ blok }: { blok: PageIntroStoryblok }) => (
  <div className="flex flex-col gap-y-2 font-inter mb-10" {...storyblokEditable(blok)}>
    <h1 className="font-medium text-3xl text-black lg:text-5xl">{blok.headline}</h1>
    <h2 className="text-lg lg:text-xl">{blok.subline}</h2>
  </div>
);

export default SbPageIntro;

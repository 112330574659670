import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import type { FeatureStoryblok } from 'shared/types';

const SbFeature = ({ blok }: { blok: FeatureStoryblok }) => (
  <div className="column feature" {...storyblokEditable(blok)}>
    {blok.name}
  </div>
);

export default SbFeature;

import classNames from 'classnames';
import React from 'react';
import BaseHead from '~/components/base-head';
import SeoMeta from '~/components/seo-meta';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Layout = ({ children, className }: Props) => {
  return (
    <div id="rbtx" className={classNames('text-brand-black h-full', className)}>
      {children}
      <BaseHead />
      <SeoMeta />
    </div>
  );
};

export default Layout;

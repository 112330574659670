import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import type { LinkButtonStoryblok } from 'shared/types';
import { LinkButton } from '../button';

const SbLinkButton = ({ blok, className }: { blok: LinkButtonStoryblok; className?: string }) => (
  <LinkButton
    className={className}
    href={blok.href?.url}
    target={blok.href?.target}
    variant={blok.variant as any}
    {...storyblokEditable(blok)}
  >
    {blok.text}
  </LinkButton>
);

export default SbLinkButton;

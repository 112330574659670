import { storyblokEditable } from '@storyblok/react';
import Image from 'next/image';
import React from 'react';
import type { ImageStoryblok } from 'shared/types';

const SbImage = ({ blok }: { blok: ImageStoryblok }) => (
  <div>
    <Image
      {...storyblokEditable(blok)}
      src={blok.asset.filename!}
      alt={blok.asset.alt!}
      width={1028}
      height={1028}
    />
  </div>
);

export default SbImage;

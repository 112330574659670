import { appWithTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import NProgress from 'nprogress';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import AlertTemplate from '~/components/alert-template';
import Layout from '~/layouts/main';
import { trackVirtualPageEvent } from '~/lib/analytics.service';
import { QueryClientProvider, QueryClient, Hydrate } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import type { AppProps } from 'next/app';
import '~/styles/global.scss';
import '~/styles/tailwind.css';
import '~/styles/nprogress.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'swiper/swiper.css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
import { ReactElement, ReactNode, useState } from 'react';
import type { NextPage } from 'next';
import { Inter, Roboto } from '@next/font/google';
import { AbbyDevtools, AbbyProvider, withAbby } from '~/lib/hooks/abby';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import { StoryblokComponents } from '~/components/storyblok/storyblok-components';

const LocaleBanner = dynamic(import('~/components/locale-banner'), {
  ssr: false
});

const inter = Inter({
  variable: '--font-inter',
  subsets: ['latin', 'vietnamese']
});

const roboto = Roboto({
  variable: '--font-roboto',
  weight: ['400', '500', '700'],
  subsets: ['latin', 'vietnamese']
});

const CartCountProvider = dynamic(import('~/components/cart-count-provider'), {
  ssr: false
});

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  containerStyle: {
    zIndex: 100
  },
  offset: '3rem',
  transition: transitions.FADE
};

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  /**
   * Defer exection because page title isn't updated here
   * https://github.com/zeit/next.js/issues/6025
   */
  setTimeout(() => trackVirtualPageEvent(), 0);

  NProgress.done();
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

Router.events.on('routeChangeError', () => NProgress.done());

storyblokInit({
  accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: StoryblokComponents,
  apiOptions: {
    region: 'eu',
    accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
    cache: {
      type: 'none'
    }
  }
});

const App = ({
  Component,
  pageProps: { __ABBY_PROJECT_DATA__, ...pageProps }
}: AppPropsWithLayout & { pageProps: Record<string, any> }) => {
  const [queryClient] = useState(() => new QueryClient());
  const getLayout = Component.getLayout ?? ((page: JSX.Element) => page);

  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-roboto: ${roboto.style.fontFamily};
            --font-inter: ${inter.style.fontFamily};
            font-family: var(--font-roboto);
          }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <Hydrate state={pageProps.dehydratedState}>
          <AbbyProvider initialData={__ABBY_PROJECT_DATA__}>
            <AbbyDevtools />
            <Layout>
              <AlertProvider
                template={AlertTemplate as any}
                {...alertOptions}
                className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 p-1"
              >
                {<LocaleBanner />}
                {getLayout(<Component {...pageProps} />)}
              </AlertProvider>
              <CartCountProvider />
            </Layout>
          </AbbyProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

export default withAbby(appWithTranslation(App));

/* eslint-disable no-undef */

declare global {
  export interface Window {
    _paq?: { push: (args: any) => void };
  }
}

function hasPiwikAnalytics() {
  return typeof window !== 'undefined' && typeof window._paq === 'object';
}

function hasGoogleAnalytics() {
  return typeof window !== 'undefined' && typeof window.ga === 'function';
}

/**
 * Tracks an event to GA
 * Tag Specification: https://igusdev.atlassian.net/wiki/spaces/KIIGUS/pages/42343338/Tracking
 */
export default function trackEvent(
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
  eventValue?: number
) {
  if (hasGoogleAnalytics()) {
    window.ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue);
  }

  if (hasPiwikAnalytics()) {
    window._paq?.push(['trackEvent', eventCategory, eventAction, eventLabel, eventValue]);
  }
}

/**
 * Tracks a virtual page view to GA
 * Tag Specification: https://igusdev.atlassian.net/wiki/spaces/KIIGUS/pages/42343338/Tracking
 */
export const trackVirtualPageEvent = () => {
  if (hasGoogleAnalytics()) {
    window.ga('send', 'pageview', window.location.pathname);
  }

  // eslint-disable-next-line no-undef
  if (hasPiwikAnalytics()) {
    window._paq?.push(['setCustomUrl', location.href.toLowerCase()]);
    window._paq?.push(['setDocumentTitle', document.title]);
    window._paq?.push(['trackPageView']);
  }
};

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import type { TeaserStoryblok } from 'shared/types';

const SbTeaser = ({ blok }: { blok: TeaserStoryblok }) => {
  return (
    <h2 className="flex flex-col lg:flex-row gap-y-8 lg:gap-x-8" {...storyblokEditable(blok)}>
      {blok.items.map((nestedBlok) => (
        <div
          className={classNames('lg:w-1/2', {
            'lg:py-12': nestedBlok.component != 'teaserItem'
          })}
          key={nestedBlok._uid}
        >
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ))}
    </h2>
  );
};

export default SbTeaser;

import classNames from 'classnames';
import { ButtonHTMLAttributes, AnchorHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const buttonClass = classNames(
  'font-medium rounded-full py-3 px-6 flex justify-center items-center hover:no-underline transition ease-in-out duration-200  disabled:opacity-40 disabled:cursor-not-allowed'
);

const defaultVariant = classNames('bg-brand-purple text-white hover:bg-[#531BEF] border');
const orangeVariant = classNames('text-white bg-brand-orange hover:bg-orange-800');
const invertedVariant = classNames(
  'bg-white text-brand-darkPurple border-brand-darkPurple hover:bg-gray-50 hover:text-brand-darkPurple border'
);

type BaseProps = {
  variant?: 'default' | 'orange' | 'inverted';
};

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & BaseProps;
type LinkButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & BaseProps;

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  ({ children, className, variant, ...props }, ref) => {
    return (
      <a
        ref={ref}
        role="button"
        className={twMerge(
          buttonClass,
          variant === 'inverted' ? invertedVariant : variant === 'orange' ? orangeVariant : defaultVariant,
          className
        )}
        {...props}
      >
        {children}
      </a>
    );
  }
);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, variant, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          buttonClass,
          variant === 'inverted' ? invertedVariant : variant === 'orange' ? orangeVariant : defaultVariant,
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

import { StoryblokComponent, renderRichText, storyblokEditable } from '@storyblok/react';
import React from 'react';
import type { TextMediaTwoColumnsStoryblok } from 'shared/types';

const SbTextMediaTwoColumns = ({ blok }: { blok: TextMediaTwoColumnsStoryblok }) => {
  const Heading = `h${blok.heading_level}` as keyof JSX.IntrinsicElements;

  return (
    <div className="flex gap-x-8" {...storyblokEditable(blok)}>
      <div className="w-1/2">
        <StoryblokComponent blok={blok.asset[0]} key={blok.asset[0].id} />
      </div>
      <div className="">
        <Heading className="text-2xl font-semibold">{blok.headline}</Heading>
        <div className="prose mt-4" dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }} />
      </div>
    </div>
  );
};

export default SbTextMediaTwoColumns;

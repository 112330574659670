import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import type { GridStoryblok } from 'shared/types';

const SbGrid = ({ blok }: { blok: GridStoryblok }) => {
  return (
    <div className="grid grid-cols-3" {...storyblokEditable(blok)}>
      {blok.columns?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  );
};

export default SbGrid;

import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import type { VideoStoryblok } from 'shared/types';

const SbVideo = ({ blok }: { blok: VideoStoryblok }) => (
  <video {...storyblokEditable(blok)} controls>
    <source src={blok.asset.filename} />
  </video>
);

export default SbVideo;

import { renderRichText, storyblokEditable } from '@storyblok/react';
import type { TeaserItemStoryblok } from 'shared/types';
import SbLinkButton from '../LinkButton';

const SbTeaserItem = ({ blok }: { blok: TeaserItemStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <h2 className="font-medium text-3xl text-black lg:text-3xl">{blok.headline}</h2>
      <div className="mt-4 prose leading-6" dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }} />
      {blok.linkButton[0] && (
        <div className="flex mt-5">
          <SbLinkButton blok={blok.linkButton[0]} />
        </div>
      )}
    </div>
  );
};

export default SbTeaserItem;

import SbLinkButton from './LinkButton';
import SbFeature from './Feature';
import SbGrid from './Grid';
import SbPage from './Page';
import SbTeaser from './atoms/Teaser';
import SbText from './text/Text';
import SbTextMediaTwoColumns from './text/TextMediaTwoColumns';
import SbImage from './asset/Image';
import SbVideo from './asset/Video';
import SbPageIntro from './header/PageIntro';
import SbTeaserItem from './atoms/TeaserItem';

const components = {
  // atoms
  teaser: SbTeaser,
  teaserItem: SbTeaserItem,

  // assets
  image: SbImage,
  video: SbVideo,

  // header
  pageIntro: SbPageIntro,

  // text folder
  text: SbText,
  textMediaTwoColumns: SbTextMediaTwoColumns,

  // unsorted
  feature: SbFeature,
  grid: SbGrid,
  page: SbPage,
  linkButton: SbLinkButton
};

export { components as StoryblokComponents };

import { createAbby } from '@tryabby/next';
import devtoolsFactory from '@tryabby/devtools';

export const { AbbyProvider, useAbby, withAbby, useFeatureFlag, withDevtools } = createAbby({
  projectId: 'clg4w474x0000ku08dd02r7lk',
  currentEnvironment: process.env.LCA_ENV,
  flags: ['CommunityDesignLink', 'fairBanner', 'showCommunityLink'],
  tests: {
    'rbtxpert-button-us': {
      variants: ['robot-demo', 'video-call']
    },
    'rbtxpert-button-de': {
      variants: ['robot-demo', 'video-call']
    }
  }
});

export const AbbyDevtools = withDevtools(devtoolsFactory, { defaultShow: true });

import React, { CSSProperties } from 'react';
import type { AlertCustomOptionsWithType } from 'react-alert';
import classNames from 'classnames';

import {
  AlertOctagon as ErrorIcon,
  AlertCircle as InfoIcon,
  CheckCircle as SuccessIcon,
  X as CloseIcon
} from 'react-feather';

import styles from './styles.module.scss';

const alertStyle = {
  color: '#4d3881'
};

type Props = {
  message: string;
  options: AlertCustomOptionsWithType;
  style: CSSProperties;
  close: () => void;
};

const AlertTemplate = ({ message, options, style, close }: Props) => {
  let icon = <InfoIcon size={48} />;

  if (options.type === 'info') {
    icon = <InfoIcon size={48} />;
  } else if (options.type === 'success') {
    icon = <SuccessIcon size={48} />;
  } else if (options.type === 'error') {
    icon = <ErrorIcon size={48} />;
  }

  return (
    <div
      className={classNames(
        'row items-center m-1 m-md-3 p-2 p-sm-4 justify-between no-gutters',
        styles['alert-template']
      )}
      style={{ ...style }}
    >
      <span className={classNames('col-auto', styles['alert-template__icon'])}>{icon}</span>
      <span className="col-8">{message}</span>
      <button
        type="button"
        className={classNames('col-auto', styles['alert-template__button '])}
        onClick={close}
      >
        <CloseIcon size={48} color={alertStyle.color} />
      </button>
    </div>
  );
};

export default AlertTemplate;

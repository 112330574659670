import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

/**
 * Validate meta tags
 * https://cards-dev.twitter.com/validator
 * https://developers.facebook.com/tools/debug/sharing/
 * Source: https://css-tricks.com/essential-meta-tags-social-media/
 */

const SeoMeta = ({ opengraph, twitter, fb }) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Head>
      {/* Opengraph */}
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={router.locale} />
      <meta property="og:image" content="/images/meta/default.png" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={t('common:defaultMetaTitle')} />
      <meta name="twitter:image" content="/images/meta/default.png" />
      <meta property="og:site:name" content="RBTX" />
      {opengraph.siteName ? <meta property="og:site:name" content={opengraph.siteName} /> : null}
      {opengraph.title ? <meta property="og:title" content={opengraph.title} /> : null}
      {opengraph.description ? <meta property="og:description" content={opengraph.description} /> : null}
      {opengraph.image ? <meta property="og:image" content={opengraph.image} /> : null}
      {opengraph.url ? <meta property="og:url" content={opengraph.url} /> : null}
      {/* Twitter */}
      {twitter.site ? <meta property="twitter:site" content={twitter.site} /> : null}
      {twitter.title ? <meta property="twitter:title" content={twitter.title} /> : null}
      {twitter.description ? <meta property="twitter:description" content={twitter.description} /> : null}
      {twitter.image ? <meta property="twitter:image" content={twitter.image} /> : null}
      {twitter.card ? <meta property="twitter:card" content={twitter.card} /> : null}
      {/* Facebook */}
      {fb.appId ? <meta property="fb:app_id" content={fb.appId} /> : null}
    </Head>
  );
};

SeoMeta.defaultProps = {
  opengraph: {},
  twitter: {},
  fb: {}
};

SeoMeta.propTypes = {
  opengraph: PropTypes.object,
  twitter: PropTypes.object,
  fb: PropTypes.object
};

export default SeoMeta;

import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import type { PageStoryblok } from 'shared/types';

const SbPage = ({ blok }: { blok: PageStoryblok }) => (
  <div className="" {...storyblokEditable(blok)}>
    {blok.body?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </div>
);

export default SbPage;

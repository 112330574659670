import { renderRichText, storyblokEditable } from '@storyblok/react';
import React from 'react';
import type { TextStoryblok } from 'shared/types';

const SbText = ({ blok }: { blok: TextStoryblok }) => (
  <p
    className="prose"
    {...storyblokEditable(blok)}
    dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }}
  />
);

export default SbText;
